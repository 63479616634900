.app {
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }
  .welcome-message, .go-button, .visitor-counter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .welcome-message {
    top: 30%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
  .go-button {
    top: 40%;
  }
  .visitor-counter {
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  .food-image-container {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .food-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px; /* Adjust based on your preference */
    height: auto;
    opacity: 0; /* Start invisible */
  }
  .animate {
    animation: slideIn 4s linear forwards;
  }
  @keyframes slideIn {
    from {
      transform: translateX(-100vw);
      opacity: 1;
    }
    to {
      transform: translateX(100vw);
      opacity: 1;
    }
  }